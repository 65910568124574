<template>
  <div class="container">
    <div class="page-title">
      <el-row>
        <el-col :span="18">
          <h3>主机管理</h3>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-button type="text" icon="el-icon-plus" @click="addDevice">新增主机</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tab-wrap">
      <el-button type="warning" size="mini" @click="showImportPop=true" class="import-btn"><i class="iconfont icon-daoru"></i> 导入</el-button>
      <el-button type="warning" size="mini" @click="exportData" class="export-btn"><i class="iconfont icon-export"></i> 导出</el-button>
      <el-tabs v-model="activeName" type="card" @tab-click="switchTab">
        <el-tab-pane label="主机列表" name="dev">
          <div class="query-box">
            <el-form :inline="true" label-width="120px" :model="queryParams" @submit.native.prevent>
              <el-row class="row">
                <el-col :span="4">
                  <el-form-item label="设备管理者" class="query-row">
                    <el-select v-model="queryParams.manager_id" clearable placeholder="请选择" style="width: 120px">
                      <template v-for="item in managerlist">
                        <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="类型" class="query-row">
                    <el-select v-model="queryParams.type_id" clearable placeholder="请选择" style="width: 120px">
                      <template v-for="item in typelist">
                        <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="维保到期日期" class="query-row">
                    <el-date-picker
                            v-model="queryParams.main_time"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期" style="width:150px">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label=" " class="query-row">
                    <el-checkbox v-model="queryParams.made_inchina" :true-label="1" :false-label="0">信创产品</el-checkbox>
                    <el-checkbox v-model="queryParams.os_inchina" :true-label="1" :false-label="0">国产系统</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="所属集群" class="query-row">
                    <el-select v-model="queryParams.group_id" clearable placeholder="请选择" style="width: 120px">
                      <template v-for="item in grouplist">
                        <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="位置" class="query-row">
                    <el-cascader v-model="queryParams.room_id" placeholder="请选择机房" :options="roomlist"
                                 :props="{ checkStrictly: true, label: 'name', value: 'id' }" clearable></el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="开始使用日期" class="query-row">
                    <el-date-picker
                            v-model="queryParams.start_usetime"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期" style="width:150px">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label="关键词"  class="query-row">
                    <el-input placeholder="输入关键词查询" v-model="queryParams.keys" clearable style="width: 320px;">
                    </el-input>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 0">
                    <el-button type="primary" native-type="submit" @click="searchData"><i class="el-icon-search"></i> 查询
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>

          <el-table :data="tableData"
                    ref="multipleTable"
                    v-loading="loading"
                    element-loading-text="加载中..."
                    style="width: 100%"
                    @selection-change="selChange">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-descriptions title="" :column="3" size="medium" border class="table-desc">
                  <el-descriptions-item label="品牌">{{ props.row.brand }}</el-descriptions-item>
                  <el-descriptions-item label="型号">{{ props.row.model_no }}</el-descriptions-item>
                  <el-descriptions-item label="序列号">{{ props.row.model_no }}</el-descriptions-item>
                  <el-descriptions-item label="固定资产编号">{{ props.row.asset_no }}</el-descriptions-item>
                  <el-descriptions-item label="信创产品">
                    <span v-if="props.row.made_inchina==1">是</span>
                    <span v-else>否</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="开始使用时间">{{ props.row.start_usetime }}</el-descriptions-item>
                  <el-descriptions-item label="维保到期时间">{{ props.row.main_time }}</el-descriptions-item>
                  <el-descriptions-item label="金额">{{ props.row.price }}</el-descriptions-item>
                  <el-descriptions-item label="备注">{{ props.row.note }}</el-descriptions-item>
                </el-descriptions>
              </template>
            </el-table-column>
            <el-table-column type="selection" width="50" fixed="left">
            </el-table-column>
            <el-table-column label="主机名称" width="140" show-overflow-tooltip>
              <template slot-scope="scope">
                {{scope.row.name}}<br/>
                <el-tag size="mini" type="warning" v-for="item in scope.row.tag" :key="item" style="margin-right: 5px">
                  {{item}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="sname" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="位置" prop="room" width="160" show-overflow-tooltip>
              <template slot-scope="scope">
                {{scope.row.roomname}} {{scope.row.cabname}}
              </template>
            </el-table-column>
            <el-table-column label="IP/MAC" prop="ip" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-for="(it,i) in scope.row.interfaces" :key="i">
                  {{it.ip}}({{it.name}}) {{it.mac}}<br/>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="CPU" prop="cpu" width="128" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="内存" prop="memory" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="磁盘空间" prop="disk" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作系统" width="140" show-overflow-tooltip>
              <template slot-scope="scope">
                {{scope.row.os}} {{scope.row.os_bit}}
              </template>
            </el-table-column>
            <el-table-column label="国产系统" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.os_inchina==1">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column label="所属集群" width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-for="(it,i) in scope.row.groups" :key="i">
                  {{it}}<br/>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="管理者" prop="manager">
            </el-table-column>
            <el-table-column label="操作" align="center" width="200" fixed="right">
              <template slot-scope="scope">
                <span class="row-opt">
                  <el-link type="primary" icon="el-icon-document" :href="'/#/device/detail?id='+scope.row.id">详情</el-link></span>
                <el-divider direction="vertical"></el-divider>
                <span class="row-opt" @click="editDevice(scope.row.id)">
                  <el-link type="primary" icon="el-icon-edit-outline">修改</el-link></span>
                <el-divider direction="vertical"></el-divider>
                <span class="row-opt" @click="deleteData(scope.row)">
                  <el-link type="primary" icon="el-icon-delete">删除</el-link></span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagenation">
            <el-row>
              <el-col :span="8" style="padding-left:10px;">
                <el-button size="small" @click="toggleSelection()">取消选择</el-button>
                <el-button type="warning" icon="delete" size="small" @click="deleteData">删除所选</el-button>
              </el-col>
              <el-col :span="16" style="text-align:right">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 50]"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="主机关系图" name="hostmap">
          <div class="total-info">
            <el-row class="row" :gutter="20">
              <el-col :span="4">
                <div class="box box-s1">
                  <el-link type="primary" class="link" @click="goPage('device')">{{sumDev.total}}</el-link>
                  <p>主机总数</p>
                </div>
              </el-col>
              <el-col :span="4" v-for="(item,index) in sumDev.type_total" :key="index">
                <div class="box box-s2">
                  <el-link type="primary" class="link" @click="goPage('type_id', item.id)">{{item.total}}</el-link>
                  <p>{{item.name}}</p>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="box box-s5">
                  <span class="link">{{sumDev.cover_total}}</span>
                  <p>防火墙覆盖</p>
                </div>
              </el-col>
              <el-col :span="4">
                <div class="box box-s6">
                  <span class="link">{{sumDev.no_cover_total}}</span>
                  <p>未被防火墙覆盖</p>
                </div>
              </el-col>
            </el-row>
          </div>
          <hw-device-chart name="hostmap" :type="1" v-if="activeName=='hostmap'"></hw-device-chart>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog :title="popTitle" :visible.sync="addPop" :close-on-click-modal="false" width="1100px" @close="resetForm">
      <hw-device-form ref="refForm" :id="id" @closePop="closePop"></hw-device-form>
    </el-dialog>

    <el-dialog title="导入设备" :visible.sync="showImportPop" :close-on-click-modal="false">
      <el-upload
              class="uploader"
              drag
              name="xlsx_file"
              :action="importExcel"
              :headers="headerObj"
              :show-file-list="false"
              :on-progress="uploading"
              :on-success="successUpload"
              :before-upload="beforeUpload"
              v-loading="waiting"
              element-loading-text="请稍等片刻>>>"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.7)"
              center>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将Excel文件拖到此处，或<em>点击导入</em></div>
        <div class="el-upload__tip" slot="tip">只能导入<span style="color: #f60">.xlsx</span>文件，且不超过2MB，请按照<a
                href="/muban/device.xlsx">模板格式</a>编辑好再导入
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
  import Deviceform from '../components/pages/DeviceForm.vue';
  import DeviceChart from '../components/pages/DeviceChart.vue';

  export default {
    name: "Device",
    components: {
      hwDeviceForm: Deviceform,
      hwDeviceChart: DeviceChart
    },
    data() {
      return {
        activeName: 'dev',
        queryParams: {
          room_id: []
        },
        loading: true,
        tableData: [],
        // 设备类型为主机设备
        deviceType: 1,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        multipleSelection: [],
        managerlist: [],
        roomlist: [],
        cablist: [],
        typelist: [],
        grouplist: [],
        addPop: false,
        popTitle: '新增主机',
        id: null,
        showImportPop: false,
        importExcel: this.$api.BaseUrl + this.$api.ImportDeviceExcel + '?type=1',
        headerObj: {
          'access-token': sessionStorage.getItem('access-token')
        },
        waiting: false,
        sumDev: {}
      }
    },

    created() {
      this.getTypeList();
      this.getListData();
      this.getManagerList();
      this.getRoomList();
      this.getGroupList();
      this.getDeviceTotal();
    },
    methods: {
      //切换tab
      switchTab(tab) {
        if (tab.name == 'dev') { //获取主机列表
          this.$nextTick(() => {
            this.searchData();
          });
        } else {
          this.$nextTick(() => {
            //this.getDeviceTotal();
          });
        }
      },
      getListData(rand = '') {
        let typeId = parseInt(this.$route.query.type_id);
        if (typeId) {
          this.queryParams.type_id = typeId;
        }
        let firewall = this.$route.query.firewall;
        if (firewall) {
          this.queryParams.firewall = firewall;
        }
        this.queryParams.page = this.currentPage;
        this.queryParams.limit = this.pageSize;
        this.queryParams.type = 1; //主机
        let url = this.$api.GetDeviceList;
        if (rand !== '') {
          url = url + '?' + rand;
        }
        this.$get(url, this.queryParams).then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total_count;
        });
      },
      getTypeList() {
        this.$get(this.$api.GetTypeList, {p_id: this.deviceType}).then((res) => {
          this.typelist = res;
        });
      },
      getManagerList() {
        this.$get(this.$api.GetManagerList).then((res) => {
          this.managerlist = res.data.list;
        });
      },
      //获取机房机柜下拉列表选项·
      getRoomList() {
        this.$get(this.$api.GetRoomOption).then((res) => {
          this.roomlist = res.data;
        });
      },
      //集群列表
      getGroupList() {
        this.$get(this.$api.GetGroupList).then((res) => {
          this.grouplist = res.data.list;
        });
      },
      searchData() {
        this.loading = true;
        this.currentPage = 1;
        this.tableData = [];
        this.total = 0;
        this.getListData();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getListData()
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.getListData()
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      selChange(val) {
        this.multipleSelection = val;
      },
      //选择列表项
      selData(id) {
        let selId = '';
        if (id) { //单个
          selId = id;
        } else { //批量
          const length = this.multipleSelection.length;
          let idsArr = []
          for (let i = 0; i < length; i++) {
            idsArr.push(this.multipleSelection[i].id)
          }
          selId = idsArr.join();
        }
        return selId;
      },

      //删除列表选项
      deleteData(row) {
        let selId = this.selData(row.id);
        if (selId == '') {
          this.$message.error('请选择要删除的项');
          return false;
        }
        this.$prompt('请输入删除此设备的原因', '提示', {
          inputValidator: function(val) {
            if (val === '' || val === null) {
              return false;
            }
          },
          inputErrorMessage: '原因不能为空'
        }).then(({ value }) => {
          this.$post(this.$api.DeleteDevice, {id: selId.toString(), reson: value})
          .then(() => {
              this.$message({
                message: '操作成功！',
                type: 'success'
              });
              this.getListData();
          });
        });
      },

      addDevice() {
        this.addPop = true;
        this.popTitle = '新增主机';
        this.id = null;
        //this.resetForm();
      },
      editDevice(id) {
        this.addPop = true;
        this.popTitle = '编辑主机';
        this.id = id;
      },
      resetForm() {
        this.$refs['refForm'].reset();
      },

      closePop(param) {
        this.addPop = false;
        this.popTitle = '新增主机';
        if (param == 'refresh') {
          this.getListData();
        }
      },
      beforeUpload(file) {
        if (file.size / 1024 / 1024 > 2) {
          this.$message.error('Excel文件大小不能超过 2MB!');
          return false;
        }

        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          return;
        } else {
          this.$message.error('只能上传.xlsx格式的Excel文件!');
          return false;
        }
      },
      successUpload(res, file) {
        console.log(file)
        this.waiting = false;
        if (res.code === 200) {
          this.$message.success('导入成功!');
          this.showImportPop = false;
          this.getListData();
        } else {
          this.$message.error(res.message);
        }
      },
      uploading(event, file) {
        console.log(event, file)
        this.waiting = true;
      },

      //导出Excel
      exportData() {
        this.queryParams.page = 'all';
        this.queryParams.responseType = 'blob';
        this.queryParams.type = 1;
        this.$download(this.$api.ExportDeviceExcel, this.queryParams).then((res) => {
          this.downloadExcel(res.data, '主机设备.xlsx');
        });
      },
      //下载保存Excel文件
      downloadExcel(data, filename) {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        let a = document.createElement("a");
        let objUrl = URL.createObjectURL(blob);
        a.href = objUrl;
        a.download = filename //文件名
        a.click();
        URL.revokeObjectURL(objUrl); // 释放内存
      },

      //设备统计信息
      getDeviceTotal() {
        this.$get(this.$api.GetDeviceTotal, {type: 1}).then((res) => {
          if (res.code === 0) {
            this.sumDev = res.data;
          } else {
            this.$message.error(res.message);
          }
        });
      },

      //跳转
      goPage(url, id) {
        this.activeName = 'dev';
        let params = {};
        if (url == 'firewall') {
          this.queryParams.firewall = id;
          delete this.queryParams.type_id;
          params = {firewall: id}
        } else if (url == 'type_id') {
          this.queryParams.type_id = id;
          delete this.queryParams.firewall;
          params = {type_id: id}
        } else {
          params = '';
        }
        this.$router.push({path: 'device', query: params});
        this.currentPage = 1;
        this.getListData();
      }
    }
  }
</script>

<style scoped lang="less">
  .tab-wrap {
    position: relative;
    padding: 0 10px;
    .import-btn {
      position: absolute;
      right: 100px;
      top: 4px;
      z-index: 999;
    }

    .export-btn {
      position: absolute;
      right: 10px;
      top: 4px;
      z-index: 999;
    }
  }

  .query-box {
    background: #f6f6f6;
    padding: 10px;
    margin: 10px 0;
    .query-row{
      margin-bottom: 10px;
    }
  }

  .pagenation {
    margin: 20px auto;
  }

  .uploader {
    width: 370px;
    margin: 0px auto;

    a {
      text-decoration: underline;
    }
  }

  .total-info {
    padding: 2px 10px;

    .row {
      line-height: 36px;

      .box {
        width: 100%;
        margin-top: 15px;
        padding: 10px 2px;
        border-radius: 6px;
        text-align: center;

        .link {
          font-weight: bold;
          font-size: 20px;
        }
      }

      .box-s1 {
        border: 1px solid #6b53c8;
      }

      .box-s2 {
        border: 1px solid #409EFF;
      }

      .box-s3 {
        border: 1px solid #67C23A;
      }

      .box-s4 {
        border: 1px solid #E6A23C;
      }

      .box-s5 {
        border: 1px solid #F56C6C;
      }

      .box-s6 {
        border: 1px solid #909399;
      }
    }
  }
  .table-desc {
    margin-left: 100px;
    margin-right: 0;
    margin-bottom: 0;
    width: 70%;
  }
</style>
