<template>
  <div class="container">
    <el-form :model="form" :rules="rules" ref="deviceForm" label-width="150px" @submit.prevent="submit('deviceForm')">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="basic">
          <el-row>
            <el-col :span="12">
              <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入主机名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="type_id">
                <el-select v-model="form.type_id" placeholder="请选择类型" style="width: 260px">
                  <template v-for="item in typelist">
                    <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="设备照片">
                <el-upload
                        class="avatar-uploader"
                        name="image_file"
                        :action="uploadUrl"
                        :data="{'m': 'device'}"
                        :headers="headerObj"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar" ref="img" id="img1">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <span style="color:#d0d0d0;">支持jpg，png格式照片，最大5M</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="位置">
                <el-cascader v-model="form.room_id" placeholder="请选择机房" :options="roomlist"
                             :props="{label: 'name', value: 'id' }" clearable style="width: 260px"></el-cascader>
                <p>{{form.gps}}</p>
                <p>{{roomGps}}</p>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="品牌" prop="brand">
                <el-input v-model="form.brand" placeholder="请输入设备品牌"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="型号" prop="model_no">
                <el-input v-model="form.model_no" placeholder="请输入设备型号" style="width: 260px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="序列号" prop="sn_no">
                <el-input v-model="form.sn_no" placeholder="请输入设备序列号 "></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="固定资产编号" prop="asset_no">
                <el-input v-model="form.asset_no" placeholder="请输入固定资产编号" style="width: 260px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="是否为信创产品" prop="made_inchina">
                <el-radio-group v-model="form.made_inchina">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="操作系统" prop="os">
                <el-select v-model="form.os" placeholder="请选择操作系统">
                  <template v-for="item in oslist">
                    <el-option :key="item" :label="item" :value="item"></el-option>
                  </template>
                </el-select>
                &nbsp;
                <el-select v-model="form.os_bit" style="width: 80px">
                  <el-option label="64位" value="64位"></el-option>
                  <el-option label="32位" value="32位"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否为国产系统" prop="made_inchina">
                <el-radio-group v-model="form.os_inchina">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="开始使用时间" prop="start_usetime">
                <el-date-picker
                        v-model="form.start_usetime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期" style="width:150px">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维保到期时间" prop="main_time">
                <el-date-picker
                        v-model="form.main_time"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期" style="width:150px">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="价格" prop="price">
                <el-input v-model="form.price" placeholder="请输入价格" style="width: 260px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="管理者" prop="manager_id">
                <el-select v-model="form.manager_id" placeholder="请选择管理者" style="width: 260px">
                  <template v-for="item in managerlist">
                    <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属集群" prop="group_id">
                <el-select v-model="form.group_id" multiple placeholder="请选择" style="width: 100%">
                  <el-option label="--无--" :value="0"></el-option>
                  <el-option
                          v-for="item in grouplist"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="标签" prop="tag">
                <el-select v-model="form.tag" multiple placeholder="请选择" style="width: 260px">
                  <el-option
                          v-for="item in taglist"
                          :key="item"
                          :label="item"
                          :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="备注" prop="note">
            <el-input v-model="form.note" type="textarea" :rows="3" placeholder="简述该设备的主要功能"></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="配置信息" name="config">
          <el-row>
            <el-col :span="12">
              <el-form-item label="CPU" prop="cpu">
                <el-input v-model="form.cpu" placeholder="请输入CPU型号，如E5-2620v4/2.1GHz "></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="内存" prop="memory">
                <el-input v-model="form.memory" placeholder="请输入内存大小型号，如32G/DDR4" style="width: 260px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="磁盘空间" prop="disk">
                <el-input v-model="form.disk" placeholder="请输入磁盘类型总空间，如SSD/120GB"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">

            </el-col>
          </el-row>
          <el-row v-for="(item,index) in form.interfaces" :key="index">
            <el-col :span="6">
              <el-form-item label="接口名称">
                <el-input v-model="item.name" placeholder="如eth0" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="IP" label-width="60px">
                <el-input v-model="item.ip" placeholder="请输入设备IP" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="MAC">
                <el-input v-model="item.mac" placeholder="请输入设备MAC" style="width: 260px"></el-input>
                <span style="margin-left:10px" v-if="index>0">
                  <el-button type="text" icon="el-icon-remove-outline" @click="removeInterface(index)">移除</el-button>
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label=" ">
                <el-button type="text" icon="el-icon-plus" @click="addInterface">增加接口</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <div style="margin-top:30px; text-align: center">
        <el-button type="primary" native-type="submit" @click.prevent="submit('deviceForm')">提交</el-button>
        <el-button @click="closePop">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import EXIF from 'exif-js';

  export default {
    name: "Deviceform",
    props: {
      id: {
        type: Number
      },
    },
    data() {
      return {
        activeName: 'basic',
        form: {
          name: '',
          mac: '',
          pic: '',
          type: '',
          type_id: '',
          room_id: [],
          gps: '',
          ip: '',
          memory: '',
          cpu: '',
          disk: '',
          os: '',
          os_bit: '64位',
          manager_id: '',
          group_id: '',
          tag: [],
          note: '',
          brand: '',
          sn_no: '',
          model_no: '',
          asset_no: '',
          made_inchina: 1,
          os_inchina: 1,
          start_usetime: null,
          price: '',
          main_time: null,
          interfaces: [
            {
              name: '',
              ip: '',
              mac: ''
            }
          ]
        },
        typelist: [
          {id: 4, name: "物理机"},
          {id: 5, name: "虚拟机"},
          {id: 6, name: "其他"}
        ],
        taglist: [],
        oslist: [],
        roomlist: [],
        managerlist: [],
        grouplist: [],
        uploadUrl: this.$api.BaseUrl + this.$api.UploadFile,
        headerObj: {
          'access-token': sessionStorage.getItem('access-token')
        },
        imageUrl: '',
        roomGps: '',
        rules: {
          name: [
            {required: true, message: '设备名称不能为空', trigger: 'blur'},
            {min: 2, max: 40, message: '长度2-40字符', trigger: 'blur'}
          ],
          ip: [
            {required: true, message: 'IP不能为空', trigger: 'blur'}
          ],
          mac: [
            {required: true, message: 'MAC不能为空', trigger: 'blur'}
          ],
          type_id: [
            {required: true, message: '请选择类型', trigger: 'change'}
          ],
          manager_id: [
            {required: true, message: '请选择管理者', trigger: 'change'}
          ],
        },
      }
    },
    watch: {
      id(val, oldVal) {
        if (val != oldVal && val != null) {
          this.getInfo();
        }
        if (val === null) {
          this.form = {};
        }
      }
    },
    mounted() {
      if (this.id != null) {
        this.getInfo();
      }
    },
    created() {
      this.getRoomList();
      this.getGroupList();
      this.getTagList();
      this.getManagerList();
      this.getOsList();
    },
    methods: {
      //获取设备详情
      getInfo() {
        this.$get(this.$api.GetDeviceInfo, {id: this.id, type: 'simple'}).then((res) => {
            if (res.data.interfaces.length === 0) {
              res.data.interfaces.push({
                name: '',
                ip: '',
                mac: ''
              })
            }
            this.form = res.data;
            this.imageUrl = res.data.pic;
            if (res.data.tag.length > 0) {
              let tags = res.data.tag.toString();
              this.form.tag = tags.split(',');
            }
        });
      },
      //机房列表
      getRoomList() {
        this.$get(this.$api.GetRoomOption).then((res) => {
            this.roomlist = res.data;
        });
      },
      //标签列表
      getTagList() {
        this.$get(this.$api.GetTagList).then((res) => {
            this.taglist = res.data;
        });
      },
      //操作系统列表
      getOsList() {
        this.$get(this.$api.GetOsList).then((res) => {
            this.oslist = res.data;
        });
      },
      //集群列表
      getGroupList() {
        this.$get(this.$api.GetGroupList).then((res) => {
            this.grouplist = res.data.list;
        });
      },
      //Manager
      getManagerList() {
        this.$get(this.$api.GetManagerList).then((res) => {
            this.managerlist = res.data.list;
        });
      },
      //新增接口
      addInterface() {
        let item = {
          name: '',
          ip: '',
          mac: ''
        };
        this.form.interfaces.push(item);
      },
      //删除一组接口
      removeInterface(i) {
        this.form.interfaces.splice(i, 1);
      },
      reset() {
        this.form = {
          name: '',
          mac: '',
          type: '',
          type_id: '',
          room_id: [],
          gps: '',
          ip: '',
          memory: '',
          cpu: '',
          disk: '',
          os: '',
          os_bit: '64位',
          manager_id: '',
          group_id: '',
          tag: [],
          note: '',
          brand: '',
          sn_no: '',
          model_no: '',
          asset_no: '',
          made_inchina: 1,
          os_inchina: 1,
          start_usetime: null,
          price: '',
          main_time: null,
          interfaces: [
            {
              name: '',
              ip: '',
              mac: ''
            }
          ]
        };
        this.imageUrl = '';
        //清空验证项
        this.$nextTick(() => {
          this.$refs['deviceForm'].clearValidate();
        });
        if (this.$refs['deviceForm']) {
          this.$refs['deviceForm'].resetFields();
        }
      },
      closePop(param) {
        //this.form = {};
        this.reset();
        this.$emit('closePop', param);
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        this.form.pic = res.data.filename;
      },
      beforeAvatarUpload(file) {
        if (file.size / 1024 / 1024 > 10) {
          this.$message.error('上传头像图片大小不能超过 10MB!');
          return false;
        }

        if (file.type === 'image/jpeg' || file.type === 'image/png') {
          // 在上传前使用 exif-js 获取文件信息
          this.getExifData(file);
          return;
        } else {
          this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
          return false;
        }
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.form.type = 1; //主机
            // if (this.form.tag.length > 0) {
            //     this.form.tag = this.form.tag.join(',');
            // }
            if (this.id != null) { //修改
              this.$prompt('请输入修改原因', '提示', {
                inputValidator: function(val) {
                  if (val === '' || val === null) {
                    return false;
                  }
                },
                inputErrorMessage: '原因不能为空'
              }).then(({ value }) => {
                this.form.reson = value;
                this.$post(this.$api.EditDevice, this.form)
                    .then(() => {
                        this.$message({
                          message: '操作成功！',
                          type: 'success'
                        });
                        this.closePop('refresh');
                    });
              });
            } else {
              this.$post(this.$api.AddDevice, this.form).then(() => {
                  this.$message({
                    message: '操作成功',
                    type: 'success'
                  });
                  this.closePop('refresh');
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 获取照片信息-经纬度
      getExifData(file) {
        // 读取图片的 EXIF 信息
        let _this = this;
        EXIF.getData(file, function() {
          const exifData = EXIF.getAllTags(this);
          // 处理 EXIF 数据，例如提取经纬度信息等
          const imgLat = exifData.GPSLatitude;
          const imgLon = exifData.GPSLongitude;
          if(imgLat && imgLon){
            //计算出经纬度并保留6为小数
            const lon = (imgLon[0] + imgLon[1]/60 + imgLon[2]/60/60).toFixed(6)
            const lat = (imgLat[0] + imgLat[1]/60 + imgLat[2]/60/60).toFixed(6)
            _this.form.gps = lon + ',' + lat;
            console.log(lon, lat);
            //获取附近的机房
            _this.$get(_this.$api.GetNearRoom, {lon: lon, lat: lat}).then((res) => {
              console.log(res)
              _this.roomGps = '该设备距离机房较近：'+ res.data.name;
              _this.form.room_id = res.data.id;
            });
          }
        });
      },

    }
  }
</script>

<style scoped>
  .avatar-uploader {
    width: 180px;
    height: 100px;
    border: 1px dashed #1E75BD;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 180px;
    height: 100px;
  }
  }
  .avatar-uploader:hover {
    border-color: #409EFF;
  }

  ::v-deep .el-tabs__nav-scroll{
    width:200px;
    margin:0 auto
  }

</style>
